<template>
  <div id="app" class="h-v">
    <keep-alive :include="getIncludeRoutes" :exclude="getExcludeRoutes">
    <router-view></router-view>
  </keep-alive>
  </div>
</template>
<script>
export default {
  computed: {
    getIncludeRoutes() {
      return this.$route.meta.keepAlive ? /.*/ : null;
    },
    getExcludeRoutes() {
      return !this.$route.meta.keepAlive ? /.*/ : null;
    }
  }
}
</script>
<style>
body{
  font-family: '微软雅黑';
}
</style>
