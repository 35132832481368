<template>
  <el-menu
    class="h-v"
    background-color="#393F4F"
    text-color="#C0C4CC"
    active-text-color="#FFFFFF"
    :collapse="isCollapse"
    :collapse-transition="false"
    router
    style="border-right: none"
    unique-opened
    :default-active="activePath"
  >
    <template v-for="item in menuList">
      <!-- 一级菜单 -->
      <router-link :to="item.path" :key="item.id + 'a'" v-if="!item.hasOwnProperty('children')">
        <el-menu-item :index="item.path + ''">
          <i :class="item.icon" class="iconfont" />
          <span>{{ item.name }}</span>
        </el-menu-item>
      </router-link>
      <el-submenu v-else :key="item.id + 1" :index="item.path + ''">
        <!-- 一级菜单模板区域 -->
        <template slot="title">
          <!-- 图标 -->
          <i :class="item.icon" class="iconfont" />
          <!-- 文本 -->
          <span>{{ item.name }}</span>
        </template>
        <!-- 二级菜单 -->
        <el-menu-item v-for="subItem in item.children" :key="subItem.id" :index="subItem.path">
          <template slot="title">
            <!-- 图标 -->
            <!-- <i :class="subItem.icon" class="iconfont" /> -->
            <!-- 文本 -->
            <span>{{ subItem.name }}</span>
          </template>
        </el-menu-item>
      </el-submenu>
    </template>
  </el-menu>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      activePath: '', // 当前路由
      menuList: [
        {
          id: 0,
          name: '智参智选运营总后台',
          path: '',
          icon: ''
        },
        {
          id: 13,
          name: '驾驶舱',
          path: '/cockpit/index',
          icon: 'icon-jr-icon-shuju'
        },
        {
          id: 1,
          name: '平台用户中心',
          path: '/user',
          icon: 'icon-jr-icon-user-1',
          children: [
            {
              id: 11,
              name: '用户列表',
              path: '/user/list'
            },
            {
              id: 12,
              name: '审核管理',
              path: '/user/examine'
            }
          ]
        },
        {
          id: 2,
          name: '智参客户中心',
          path: '/customer/list',
          icon: 'icon-jr-icon-qiye',
          children: [
            {
              id: 101,
              name: '客户中心',
              path: '/customer/list'
            },
            {
              id: 102,
              name: '报备中心',
              path: '/customer/Reporting'
            }
          ]
        },
        {
          id: 8,
          name: '智选客户中心',
          path: '/selection/index',
          icon: 'icon-jr-icon-kehu'
        },
        {
          id: 9,
          name: '合作伙伴中心',
          path: '/channel/index',
          icon: 'icon-jr-icon-buildings',
          children: [
            {
              id: 91,
              name: '合作伙伴',
              path: '/channel/index'
            },
            {
              id: 92,
              name: '智参中心',
              path: '/channel/ginseng'
            },
            // {
            //   id: 93,
            //   name: '合作开发',
            //   path: '/channel/development'
            // },
            // {
            //   id: 93,
            //   name: '服务建议',
            //   path: '/channel/Servicesuggestions'
            // }
          ]
        },
        {
          id: 21,
          name: '运营商中心',
          path: '/operatorcenter/operatormanage',
          icon: 'icon-jr-icon-buildings',
          children: [
            // {
            //   id: 21,
            //   name: '运营商中心',
            //   path: '/operatorcenter/operatormanage'
            // },
            {
              id: 121,
              name: '运营商管理',
              path: '/operatorcenter/operatormanage'
            },
            {
              id: 122,
              name: '商机挖掘',
              path: '/operatorcenter/nicheexcavate'
            },
            {
              id: 93,
              name: '服务建议',
              path: '/channel/Servicesuggestions'
            }
            // {
            //   id: 122,
            //   name: '建议管理',
            //   path: '/operatorcenter/suggestionmanage'
            // }
          ]
        },
        {
          id: 20,
          name: '运营管理中心',
          path: '/operate/archive',
          icon: 'icon-jr-icon-three',
          children: [
            {
              id: 3,
              name: '内容管理',
              path: '/content/list'
              // children: [
              //   {
              //     id: 31,
              //     name: '内容管理',
              //     path: '/content/list'
              //   }
              // ]
            },
            {
              id: 14,
              name: '报告管理',
              path: '/content/report'
              // icon: 'icon-jr-icon-group'
            },
            {
              id: 7,
              name: '活动服务',
              path: '/activity/list'
              // icon: 'icon-jr-icon-liwu'
            },
            {
              id: 12,
              name: '专家服务',
              path: '/expert/index'
              // icon: 'icon-jr-icon-zhanshi'
            },
            {
              id: 20,
              name: '运营档案',
              path: '/operate/archive'
            },
            {
              id: 22,
              name: '运营分析',
              path: '/operate/analysis'
            }
          ]
        },
        // {
        //   id: 3,
        //   name: '内容管理中心',
        //   path: '/content/list',
        //   icon: 'icon-jr-icon-one'
        //   // children: [
        //   //   {
        //   //     id: 31,
        //   //     name: '内容管理',
        //   //     path: '/content/list'
        //   //   }
        //   // ]
        // },
        // {
        //   id: 14,
        //   name: '报告管理中心',
        //   path: '/content/report',
        //   icon: 'icon-jr-icon-group'
        // },
        // {
        //   id: 7,
        //   name: '活动服务中心',
        //   path: '/activity/list',
        //   icon: 'icon-jr-icon-liwu'
        // },
        // {
        //   id: 12,
        //   name: '专家服务中心',
        //   path: '/expert/index',
        //   icon: 'icon-jr-icon-zhanshi'
        // },

        {
          id: 5,
          name: '顾问服务中心',
          path: '/adviser',
          icon: 'icon-jr-icon-three',
          children: [
            {
              id: 51,
              name: '在线服务',
              path: '/adviser/online'
            }
          ]
        },
        {
          id: 6,
          // name: '线索服务中心',
          name: '商机管理中心',
          path: '/clue',
          icon: 'icon-jr-icon-two',
          children: [
            {
              id: 61,
              name: '线索管理',
              path: '/clue/index'
            },
            {
              id: 62,
              name: '商机管理',
              path: '/clue/opportunity'
            },
            {
              id: 63,
              name: '业务全景',
              path: '/clue/business'
            },
            {
              id: 64,
              name: '商机分配',
              path: '/clue/distribution'
            },
            {
              id: 65,
              name: '售前建议',
              path: '/recommendations/index',
            }
          ]
        },

        {
          id: 4,
          name: '诊断服务中心',
          path: '/diagnosis',
          icon: 'icon-jr-icon-four',
          children: [
            {
              id: 41,
              name: '诊断记录',
              path: '/diagnosis/record'
            },
            {
              id: 42,
              name: '预约记录',
              path: '/diagnosis/make'
            },
            {
              id: 43,
              name: '诊断工具',
              path: '/diagnosis/diagnostictool'
            },
            {
              id: 44,
              name: '问题管理',
              path: '/diagnosis/troublemanagement'
            },
            {
              id: 45,
              name: '政策管理',
              path: '/diagnosis/policymanagement'
            }
          ]
        },

        // {
        //   id: 7,
        //   name: '活动服务中心',
        //   path: '/activity/list',
        //   icon: 'icon-jr-icon-liwu'
        // },

        // {
        //   id: 12,
        //   name: '专家服务中心',
        //   path: '/expert/index',
        //   icon: 'icon-jr-icon-zhanshi'
        // },
        {
          id: 10,
          name: '订单管理中心',
          path: '/order/index',
          icon: 'icon-jr-icon-five'
        },

        {
          id: 11,
          name: '系统管理',
          path: '/system/index',
          icon: 'icon-jr-icon-seven',
          children: [
            {
              id: 12,
              name: '智参设置',
              path: '/system/ginseng'
            },
            {
              id: 18,
              name: '智选设置',
              path: '/system/Smartselection'
            },
            {
              id: 13,
              name: '意见管理',
              path: '/system/idea'
            },
            {
              id: 14,
              name: '标签管理',
              path: '/system/labelmanage'
            },
            {
              id: 15,
              name: '反馈收集',
              path: '/system/feedback'
            },
            {
              id: 16,
              name: '菜单管理',
              path: '/system/menu'
            },
            {
              id: 17,
              name: '匹配管理',
              path: '/system/matemanage'
            },
            {
              id: 19,
              name: '周报管理',
              path: '/system/weeklymanage'
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont {
  color: #fff;
  margin-right: 12px;
}
/deep/ .el-menu-item.is-active {
  color: #fff !important;
  background-color: rgb(46, 50, 63) !important;
}
</style>
