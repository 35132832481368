import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/theme/index.css'
import 'normalize.css'
import './assets/css/bts.less'
import './assets/css/mine.less'
import '../static/font/iconfont.css'
//单个图片上传
import ImgBigUpload from '@/components/ImgBigUpload'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.component('ImgBigUpload', ImgBigUpload)

import VDistpicker from 'v-distpicker'
Vue.component('v-distpicker', VDistpicker);

// import echarts from 'echarts'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.filter('date', (val) => {
  if (val) {
    val = val.toString().split(' ')[0]
    return val
  } else {
    return ''
  }
})

Vue.filter('time', (val) => {
  if (val) {
    val = val.toString().split(' ')[1]
    return val
  } else {
    return ''
  }
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
